// Migrated
<template>
  <div
    class="w-full relative pb-print-12 flex flex-col justify-center items-center pt-print-12 z-[3] print-hidden"
    :class="{ 'layout-head': !noMedia, 'pt-12': noMedia }"
  >
    <div
      v-if="bannerSpecial"
      class="absolute top-0 right-0"
    >
      <LazySpecialBookmark
        v-if="bannerSpecial.type === specials.bookmark"
        v-bind="bannerSpecial.data"
      />
      <div
        v-else-if="bannerSpecial.type === specials.crown"
        class="pt-2 md:pt-3 -my-1 -mr-1 md:mr-0"
      >
        <LazyCrownText />
      </div>
    </div>
    <div class="banner-text-container flex flex-col justify-content-top items-center w-full">
      <ResponsiveText
        class="w-full mb-2 text-center break-normal banner-text-title text-7xl"
        as="h1"
      >
        <span
          class="text-white font-bold drop-shadow-dark"
          :data-i18n="bannerTitleI18n"
          data-allow-mismatch
        >{{ bannerTitle }}</span>
      </ResponsiveText>
      <h2
        v-if="bannerSubtitle && !bannerSubtitleColor"
        class="text-white text-center banner-text-subtitle drop-shadow-dark"
        :class="bannerSubtitleClasses"
        :style="bannerSubtitleStyle"
        :data-i18n="bannerSubtitleI18n"
      >
        {{ bannerSubtitle }}
      </h2>
      <div
        v-if="bannerSubtitle && bannerSubtitleColor"
        class="header-2xl text-center banner-text-subtitle drop-shadow-dark"
        :class="bannerSubtitleClasses"
        :style="bannerSubtitleStyle"
      >
        {{ bannerSubtitle }}
      </div>
      <div
        v-if="bannerStars?.score"
        class="flex items-center drop-shadow-light mb-2 mt-2 text-center"
      >
        <LazyStars
          class="mr-2"
          is-star
          :score="bannerStars?.score"
          :size="bannerStars.reviewCount ? 'sm' : 'lg'"
          type="inline"
        />
        <span
          v-if="bannerStars?.reviewCount"
          class="text-white ml-2 drop-shadow-medium"
        >{{ $t('outOfReviews', { n: bannerStars.reviewCount }) }}</span>
      </div>
      <div
        v-if="bannerPrice || bannerDays"
        class="text-white font-bold drop-shadow-dark text-center"
      >
        <h2
          v-if="bannerDays"
          class="font-serif mb-0 inline mr-2 pr-1 banner-text-subtitle"
        >
          {{ bannerDays }}
        </h2>
        <template v-if="bannerPrice">
          <div class="font-serif mb-0 mr-2 inline-block">
            {{ $t('shortFromPrice') }}
          </div>
          <h4
            v-if="bannerPriceWithoutDiscount"
            class="font-serif mb-0 mr-2 inline-block strikethrough before:!border-white"
          >
            {{ $n(bannerPriceWithoutDiscount) }}
          </h4>
          <h3 class="font-serif mb-0 mr-2 inline-block">
            {{ $n(bannerPrice) }}
          </h3>
        </template>
      </div>
      <template v-if="bannerLink || bannerLinkButton">
        <template v-if="$slots.link">
          <slot name="link" />
        </template>
        <nuxt-link
          v-else-if="bannerLink"
          class="h4 mt-4 !text-white font-medium w-full text-center"
          :to="bannerLink?.href || ''"
        >
          <span class="drop-shadow-medium">
            {{ bannerLink?.text }}
          </span>
          <fa
            class="ml-4 mt-1 absolute"
            icon="arrow-right-long"
          />
        </nuxt-link>
        <button
          v-else-if="bannerLinkButton"
          class="h4 mt-4 py-2 px-6 !text-white font-medium text-center"
          :class="bannerLinkButton?.bgColor ? `bg-${bannerLinkButton.bgColor}` : ''"
          @click="scrollToBanner(bannerLinkButton?.href)"
        >
          <span class="drop-shadow-medium">
            {{ bannerLinkButton?.text }}
          </span>
        </button>
      </template>
    </div>
  </div>
</template>

<script setup>
import { specials } from '@layers/web/constants/banner'
import colors from '#tailwind-config/theme/colors'

defineProps({
  noMedia: {
    type: Boolean,
    default: false,
  },

  sidebarCollapsible: {
    type: Boolean,
    default: false,
  },
})

const rootStore = useRootStore()

const {
  pageBannerTitle: bannerTitle,
  pageBannerTitleI18n: bannerTitleI18n,
  pageBannerSubtitle: bannerSubtitle,
  pageBannerSubtitleI18n: bannerSubtitleI18n,
  pageBannerSubtitleColor: bannerSubtitleColor,
  pageBannerSubtitleWeight: bannerSubtitleWeight,
  pageBannerLink: bannerLink,
  pageBannerLinkButton: bannerLinkButton,
  pageBannerStars: bannerStars,
  pageBannerPrice: bannerPrice,
  bannerPriceWithoutDiscount: bannerPriceWithoutDiscount,
  pageBannerDays: bannerDays,
  pageBannerSpecial: bannerSpecial,
} = storeToRefs(rootStore)

const bannerSubtitleStyle = computed(
  () => ({
    fontWeight: bannerSubtitleWeight?.value || 'normal',
    color: colors[bannerSubtitleColor.value],
  })
)

const bannerSubtitleClasses = computed(() => {
  const classes = []

  if (bannerSubtitleColor?.value) {
    classes.push(`text-${bannerSubtitleColor.value}`)
  }

  return classes.join(' ')
})

const scrollToBanner = (bannerID) => {
  const findBanner = document.querySelector(`#${bannerID}`)

  if (findBanner) {
    findBanner.scrollIntoView({
      behavior: 'smooth',
    })
  }
}
</script>
