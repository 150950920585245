// Migrated
<template>
  <header
    class="layout-head -z-[1] absolute w-full left-0 print-hidden"
    :critical="true"
  >
    <LazyMediaPlayer
      v-if="isVideo"
      :url="video.url"
      :type="video.type"
      :just-preview-poster="video.poster"
      just-preview
      autoplay
      muted
    />
    <LazyResponsiveHotelImage
      v-else-if="image.type !== 'cloudinary'"
      class="size-full"
      :image="image.url"
      :type="image.type"
      :alt="image.alt"
    />
    <LazyResponsiveImage
      v-else
      class="h-full"
      :align="image.align"
      :alt="image.alt"
      :image="image.url"
      :gravity="image.gravity"
      :lazy="false"
      :type="image.type"
      cover
      preload
      hero
    />
  </header>
</template>

<script>
import { mapState } from 'pinia'

export default defineNuxtComponent({
  setup () {
    const { public: { images: imagesConfig } } = useRuntimeConfig()

    return {
      imagesConfig,
    }
  },

  computed: {
    ...mapState(useRootStore, {
      bannerTitle: 'pageBannerTitle',
      bannerSubtitle: 'pageBannerSubtitle',
      bannerLink: 'pageBannerLink',
      bannerType: 'pageBannerType',
      bannerBackground: 'pageBannerBackground',
      bannerStars: 'pageBannerStars',
      bannerPrice: 'pageBannerPrice',
      bannerDays: 'pageBannerDays',
      pageExtraFooter: 'pageExtraFooter',
    }),

    fallbackImage () {
      return this.imagesConfig.fallbackHeroId
    },

    getBannerType () {
      return this.bannerType ?? 'default'
    },

    getBannerBackground () {
      // fallback if not set
      if (Object.keys(this.bannerBackground || {}).length === 0) {
        return { image: { url: this.fallbackImage } }
      }

      // the old way
      if (this.bannerBackground?.picture_cloudinary) {
        const url = this.bannerBackground?.picture_cloudinary === 'hero/rome_sqg4ot'
          ? this.fallbackImage
          : this.bannerBackground?.picture_cloudinary
        return {
          image: {
            url,
            type: 'cloudinary',
          },
        }
      }

      // preferred and proper way
      return this.bannerBackground
    },

    isVideo () {
      // return this.bannerBackground?.video
      return false
    },

    video () {
      return {
        url: '',
        type: '',
        poster: '',
        ...(this.bannerBackground?.video || {}),
      }
    },

    image () {
      const image = this.getBannerBackground?.image

      return {
        url: this.fallbackImage,
        align: this.getBannerBackground?.align || null,
        ...(image || {}),
        alt: this.bannerTitle || this.bannerSubtitle || this.$t('companyName'),
        type: !image?.type ? 'cloudinary' : image?.type,
      }
    },
  },
})
</script>
